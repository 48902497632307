import { render, staticRenderFns } from "./HeaderExam.vue?vue&type=template&id=58825328&scoped=true"
import script from "./HeaderExam.vue?vue&type=script&lang=ts"
export * from "./HeaderExam.vue?vue&type=script&lang=ts"
import style0 from "./HeaderExam.vue?vue&type=style&index=0&id=58825328&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58825328",
  null
  
)

export default component.exports