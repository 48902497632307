<template>
    <div class="header">
    <button class="titleBtn btn btn-link"
      @click="tapLogo"
    >ファイナンシャル・プランニング技能検定３級CBT  学科試験無料版</button>
      <div class="ad-button float-end">
        <a class="btn big bgYelGd tBlk fzXs bB my-4 mx-auto ml-md-0 mr-md-4" href="https://wakaruukaru.jp/fp" target="_blank" rel="noopener"><span>もっとCBT試験を体験</span></a>
      </div>
    <hr class="m-0"/>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from "vue-property-decorator";

import StorageService from "../services/storage";

@Component
export default class Header extends Vue {

  created() {
  }
  mounted() {}

  tapLogo() {
    if (this.$store.state.data.length == 0) {
    if (this.$route.name == "home") return location.href = "/";
    return location.href = "/text";
    } else {
      const shikenId = this.$store.state.data[0].shikenId;
    if (shikenId == 1) return location.href = "/";
    return location.href = "/text";
    }
    
  }
}
</script>
<style scoped>
.header {
  height: 70px;
}

.header a {
  line-height: 70px;
  text-decoration: none;
  font-weight: bold;
  color: #212529;
}
.header .titleBtn {
  line-height: 70px;
  text-decoration: none;
  font-weight: bold;
  color: #212529;
}

.ad-button {
  height: 70px;
}

.btn.big {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    padding-right: 1em;
    padding-left: 1em;
    text-align: left;
    border-radius: 30px;
    line-height: 1.6em;
    font-weight: bold;
    color: #010101;
    background: transparent linear-gradient(180deg, #fff600 0%, #ebb100 100%) 0% 0% no-repeat;
}

.btn {
  border: none;
    transition: all 300ms ease-out;
}

</style>