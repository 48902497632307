<template>
<div class="py-5">
  <h3 class="grn">ＦＰ３級受検者必見！</h3>
  <h1 class="mb-3">FP3級CBT方式試験 トライアル版 無料開放！</h1>
  <p>2023 年4 月26 日に一般社団法人金融財政事情研究会（<strong>金財</strong>）、NPO 団体　日本ファイナンシャル・プランナー協会（<strong>日本FP 協会</strong>）より受検者の利便性向上するために、2024 年度から<strong>FP技能検定３級をCBT 方式へ全面移行</strong>することが発表されました。
本ページでは初めてＦＰ試験を受検される方向けにＣＢＴ方式試験（学科試験）のトライアル版を無料開放中です。これからＦＰ３級の取得を考えている方もぜひ、ご参考ください。
</p>

<div class="index-box">
  <div>■INDEX■</div>
  <ol>
    <li><a href="#sec1">CBT方式試験とは？</a></li>
    <li><a href="#sec2">受検の申込方法は？</a></li>
    <li><a href="#sec3">いつから予約できる？</a></li>
    <li><a href="#sec4">ＣＢＴ試験を体験する</a></li>
  </ol>
</div>

<h2 id="sec1" class="mt-5">１．CBT方式試験とは？</h2>
<p>ＣＢＴは、 Computer Based Testing (コンピュータ ベースド テスティング)の略称で、コンピュータを使って実施される試験のことをいいます。これまで年3回だった本試験が、<strong>通年（休止期間あり）で実施</strong>されますので、受検者の利便性や公平性が飛躍的に向上されます。
加えて、<strong>試験当日にスコア（試験結果）がわかる</strong>のもＣＢＴ方式試験の大きなメリットです。<br>
※CBT方式試験は、金財は2023年11月、日本ＦＰ協会は2024年4月より実施。
</p>

<h2 id="sec2" class="mt-5">２．受検の申込方法は？</h2>
<p>受検する資格の団体（金財、または日本ＦＰ協会）のホームページへアクセスし、<strong>日時・場所（テストセンター）を予約して受検</strong>します。</p>

<h5>▽申し込みの流れ</h5>
<div class="num">①金財、日本ＦＰ協会のホームページにアクセスする</div>
　金財：<a href="https://www.kinzai.or.jp/ginoucbt" target="_blank">https://www.kinzai.or.jp/ginoucbt</a><br>
　日本ＦＰ協会：<a href="https://www.jafp.or.jp/" target="_blank">https://www.jafp.or.jp/</a>　※2024年4月1日より申込開始
<div class="arrow">↓</div>
<div class="num">②受検者情報（氏名や生年月日、メールアドレスなど）を登録し、申込に必要なユーザーIDを取得する</div>
<div class="arrow">↓</div>
<div class="num">③取得したユーザーIDにて受検日時、場所（テストセンター）を指定し予約する</div>
　受検料の支払いが確定すると予約完了<br>
　支払方法はクレジットカード決済、コンビニ決済など
<div class="arrow">↓</div>
<div class="num">④試験当日、予約したテストセンターにて受検 </div>
　指定された本人確認書類を持参する
<div class="arrow">↓</div>
<div class="num">⑤試験後、受付にてスコアレポート（試験結果）を受け取り終了</div>
<p>　合格証書は後日郵送
</p>
<h2 id="sec3" class="mt-5">３．いつから予約できる？</h2>
<p>
  受検予約画面では、基本的に最長で<strong>申請月を含ま ない 3 か月後</strong>（1月申請の場合4月）～<strong>試験日3日前</strong>までを選択することが可能です。<br>
ただし、テストセンターの座席数には限りがあるので、受検する目標日を決めて、早めに申請することをおすすめします。<br>
<br>
年末年始など、試験休止期間があります。試験日程は、各試験団体のホームページにてご確認ください。<br>
金財：<a href="https://www.kinzai.or.jp/fp/nittei-fp/41870.html" target="_blank">https://www.kinzai.or.jp/fp/nittei-fp/41870.html</a><br>
日本ＦＰ協会：<a href="https://www.jafp.or.jp/exam/schedule/" target="_blank">https://www.jafp.or.jp/exam/schedule/</a><br>
※日本ＦＰ協会は2024年4月1日午前 10 時より申請受付開始し、4月4日より試験実施となります。
</p>
<h2 id="sec4" class="mt-5">４．ＣＢＴ試験を体験する</h2>
<p>では、ＣＢＴ方式試験を体験してみましょう。<br>
このＣＢＴ方式試験のトライアル版は、『<a href="https://wakaruukaru.jp/fp" target="_blank">わかる！受かる！！ ＦＰ３級 徹底分析！予想模試</a>』（マイナビ出版刊）の<strong>予想模試「第１回」の学科試験（正誤問題）３０問</strong>が体験できます。
</p>
<hr>
  <p>
    本アプリは、パソコンにてご利用ください。<br>
【推奨環境】<br>
・Google Chrome（最新版）<br>・Microsoft Edge（最新版）<br>・Firefox（最新版）<br>・Safari （最新版）
    <div>※ 画面表示は、実際のCBT方式試験とは同一ではありません。また、改善のため予告なく変更される場合がありますので、あらかじめご了承ください。</div>
  </p>

  <h3>注意事項</h3>
  <ol>
    <li>出題形式は、<strong>正誤式 30問</strong>です。
</li>
    <li>試験時間は、<strong>60分</strong>です。「試験開始」ボタンをクリックすると試験がスタートし、画面右上に残り時間が表示されます。
</li>
    <li>試験問題は、特に指示のない限り2023年４月１日現在施行の法令等に基づいて解答してください。
</li>
<li>「後で見直す」ボタンをクリックすると、表示されている問題に赤い目印がつき、後で見直すためのチェックを入れることができます。</li>
    <li>画面下の「解答状況」ボタンをクリックすると、「未解答」、「解答ずみ」、「チェックしたもの」、「解答中」の問題が一覧で確認できます。
</li>
<li>画面下の「<strong>電卓</strong>」ボタンをクリックすると、画面上に電卓機能が表示されますので、計算が必要な場合にご利用ください。</li>
<li>問１～問30は、文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。
</li>
    <li>30点満点で、<strong>18点以上</strong>が合格です。
</li>
    <li>試験終了後、試験結果が表示されます。<strong>※この機能は実際のＣＢＴ試験にはありません。</strong>
</li>
  </ol>
<hr class="mt-5"/>

<div class="text-end mt-4">
  <button  @click="exec(1)" class="btn btn-primary">試験開始</button>
</div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Emit } from "vue-property-decorator";
import HttpClient from "../services/httpClient";
import BasicUtil from "../services/basicUtil";
import StorageService from "../services/storage";


@Component({
  components: {}
})
export default class HomeView extends Vue {
  private dao: HttpClient = new HttpClient();
  private StorageService: StorageService = new StorageService();

  private grade: string = this.$route.params.grade ?? "3";
  private shikenId: number = 1
  created() {

  }
  mounted() {
    const shikenId = this.$route.name == "home" ? 1 : 2
    this.shikenId = shikenId
    new BasicUtil().removeBasicSetting(shikenId);
    new StorageService().removeCurrentData();
  }

  private exec(shikenId: number) {
    const datas = new HttpClient().getQuestionsFromAnswerType(shikenId, 1)
    this.$store.commit("setQuestionBacks", []);
    this.$store.commit("setQuestions", []);
    this.$store.commit("setQuestions", datas);
    this.$store.commit("setIsRe", false)
    this.$store.commit("setGoal", new Date().getTime() + 120 * 60 * 1000);
    new BasicUtil().setBasicSetting(shikenId);
    this.$router.push(`/exam/${shikenId}/1`);
  }

}
</script>
<style scoped>
h1 {
  font-size: 1.8em;
}

h2 {
  font-size:  1.2em;
}

h3 {
  font-size: 1em;
}

.num {
  font-size: 1.1em;
  font-weight: bold;
}

.grn {
 color: #01b2a0;
}

.arrow {
  margin: 0.2em 0;
  width: 100px;
  text-align: center;
}

.index-box {
  border: solid 1px #999;
  background-color: #f2f2f2;
  padding: 1em 2em;
  display: inline-block;
}

.index-box ol {
  padding: 0;
  padding-left: 1em;
  margin: 0;
}
</style>
