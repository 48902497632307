
export default [
    {
        "id": 1,
        "shikenId": 1,
        "no": 1,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "税理士資格を有しないファイナンシャル・プランナーが、顧客のために相談業務の延長として確定申告書を作成した場合、その行為が無償であれば税理士法に抵触しない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "関連法規とコンプライアンス",
        "important": "A"
    },
    {
        "id": 2,
        "shikenId": 1,
        "no": 2,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "遺族基礎年金を受給することができる遺族は、国民年金の被保険者等の死亡の当時に、その者によって生計を維持されており、かつ、所定の要件を満たす「子のある妻」または「子」である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "遺族基礎年金",
        "important": "C"
    },
    {
        "id": 3,
        "shikenId": 1,
        "no": 3,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "労働者災害補償保険（労災保険）の保険料は、労働者と事業主が折半で負担する。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "労働者災害補償保険",
        "important": "C"
    },
    {
        "id": 4,
        "shikenId": 1,
        "no": 4,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "確定拠出年金の老齢給付金を60歳から受給するには、60歳到達時の通算加入者等期間が10年以上なければならない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "確定拠出年金",
        "important": "A"
    },
    {
        "id": 5,
        "shikenId": 1,
        "no": 5,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "住宅金融支援機構と民間金融機関が提携した住宅ローンであるフラット35の融資金利は固定金利であるが、その利率は取扱金融機関がそれぞれ独自に決定している。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "フラット35",
        "important": "B"
    },
    {
        "id": 6,
        "shikenId": 1,
        "no": 6,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "国内銀行の窓口で加入した生命保険契約については、生命保険契約者保護機構による補償の対象とならない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "保険契約者保護",
        "important": "B"
    },
    {
        "id": 7,
        "shikenId": 1,
        "no": 7,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "生命保険の保険料のうち、保険会社が保険契約を維持・管理するための費用に当てられる付加保険料は、予定死亡率および予定利率に基づいて計算される。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "生命保険料の仕組み",
        "important": "A"
    },
    {
        "id": 8,
        "shikenId": 1,
        "no": 8,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "一般に、収入保障保険の死亡保険金を年金形式で受け取る場合の受取総額は、一時金で受け取る場合の受取額よりも少なくなる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "収入保障保険",
        "important": "C"
    },
    {
        "id": 9,
        "shikenId": 1,
        "no": 9,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "生命保険契約にある入院特約に基づき、被保険者が病気で入院したことで被保険者が受け取った入院給付金は、非課税である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "給付金と税金",
        "important": "B"
    },
    {
        "id": 10,
        "shikenId": 1,
        "no": 10,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "自動車損害賠償責任保険において、被害者１人当たりの保険金の支払限度額は、死亡の場合で3,000万円、後遺障害の場合で5,000万円である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "自動車損害賠償責任保険",
        "important": "B"
    },
    {
        "id": 11,
        "shikenId": 1,
        "no": 11,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "米国の市場金利が上昇し、日本の市場金利が低下することは、米ドルと円の為替相場においては、一般に、米ドル安、円高の要因となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "為替相場の変動要因",
        "important": "B"
    },
    {
        "id": 12,
        "shikenId": 1,
        "no": 12,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "上場投資信託（ＥＴＦ）は、上場株式と同様に、指値注文や成行注文により売買することができる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "上場投資信託（ETF）",
        "important": "C"
    },
    {
        "id": 13,
        "shikenId": 1,
        "no": 13,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "債券を発行する企業の信用度が低下し、格付が引き下げられた場合、一般に、その債券の価格は下落し、利回りも低下する。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "債券のリスク",
        "important": "A"
    },
    {
        "id": 14,
        "shikenId": 1,
        "no": 14,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "Ｘ社の株価が1,200円、１株当たり純利益が24円、１株当たり年間配当金が12円である場合、Ｘ社株式の配当利回りは、１％である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "株式の投資指標の計算",
        "important": "A"
    },
    {
        "id": 15,
        "shikenId": 1,
        "no": 15,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "預金保険制度の対象金融機関に預け入れた決済用預金は、預入金額にかかわらず、その全額が預金保険制度による保護の対象となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "預金保険制度",
        "important": "B"
    },
    {
        "id": 16,
        "shikenId": 1,
        "no": 16,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "個人が法人からの贈与により取得した財産は、原則として贈与税の課税対象となり、所得税は課されない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "贈与財産の課税",
        "important": "B"
    },
    {
        "id": 17,
        "shikenId": 1,
        "no": 17,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "電車・バス等の交通機関を利用して通勤している給与所得者が、勤務先から受ける通勤手当の所得税法上の非課税限度額は、月額10万円である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "非課税所得",
        "important": "C"
    },
    {
        "id": 18,
        "shikenId": 1,
        "no": 18,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "所得税において、事業的規模で行われている賃貸マンションの貸付で得た所得は、不動産所得となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "不動産所得",
        "important": "B"
    },
    {
        "id": 19,
        "shikenId": 1,
        "no": 19,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "所得税における基礎控除の額は、納税者の合計所得金額の多寡にかかわらず、一律で38万円である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "基礎控除",
        "important": "C"
    },
    {
        "id": 20,
        "shikenId": 1,
        "no": 20,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "夫が生計を一にする妻の負担すべき国民年金の保険料を支払った場合、その支払った金額は、夫の所得税において、社会保険料控除の対象となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "社会保険料控除",
        "important": "B"
    },
    {
        "id": 21,
        "shikenId": 1,
        "no": 21,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "不動産の登記事項証明書の交付請求は、対象不動産の所有者のみが行うことができる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "不動産登記",
        "important": "A"
    },
    {
        "id": 22,
        "shikenId": 1,
        "no": 22,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "不動産取引において、買主が売主に解約手付を交付したときは、相手方が契約の履行に着手するまでは、買主はその手付を放棄することで、売主はその手付を買主に現実に提供することで、契約を解除することができる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "手付金",
        "important": "B"
    },
    {
        "id": 23,
        "shikenId": 1,
        "no": 23,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "都市計画法の規定によれば、市街化区域内で行う開発行為は、その規模にかかわらず、都道府県知事等の許可が必要である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "開発許可",
        "important": "B"
    },
    {
        "id": 24,
        "shikenId": 1,
        "no": 24,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "不動産取得税は、生前贈与や相続により不動産を取得したときには課されない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "不動産取得税",
        "important": "C"
    },
    {
        "id": 25,
        "shikenId": 1,
        "no": 25,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "土地の譲渡所得のうち、その土地を譲渡した日の属する年の１月１日における所有期間が10年以下のものは、短期譲渡所得に区分される。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "不動産の譲渡と所有期間",
        "important": "B"
    },
    {
        "id": 26,
        "shikenId": 1,
        "no": 26,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "相続人が相続の放棄をする場合は、自己のために相続の開始があったことを知った時から、原則として10カ月以内に、家庭裁判所にその旨を申述しなければならない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "相続の放棄",
        "important": "C"
    },
    {
        "id": 27,
        "shikenId": 1,
        "no": 27,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "相続税額の計算上、被相続人が生前に購入した墓碑の購入代金で、相続開始時において未払いのものは、債務控除の対象となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "債務控除",
        "important": "B"
    },
    {
        "id": 28,
        "shikenId": 1,
        "no": 28,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "本年中に相続や遺贈により財産を取得した者が、相続開始前５年以内に被相続人から暦年課税方式の贈与により取得した財産は、相続税額の計算上、相続財産に加算される。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "生前贈与加算",
        "important": "C"
    },
    {
        "id": 29,
        "shikenId": 1,
        "no": 29,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "「直系尊属から教育資金の一括贈与を受けた場合の贈与税の非課税」は、贈与を受ける年の受贈者の合計所得金額が1,000万円を超える場合、適用を受けることができない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "贈与税非課税（教育資金）",
        "important": "B"
    },
    {
        "id": 30,
        "shikenId": 1,
        "no": 30,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "相続財産の評価において、相続開始時に保険事故が発生していない生命保険契約に関する権利の価額は、原則として、既払込保険料相当額により評価する。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "生命保険契約に関する権利",
        "important": "C"
    },
    ]